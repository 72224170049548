import * as React from 'react';
import { Card, CardContent, CardMedia } from '@mui/material';
import arrowRight from '../../../../static/images/arrowRightDefault.svg';
import theme, { COLORS, BREAKPOINTS } from '../../../utils/theme';
import LinkWrapper from '../Link';
import { Box } from '@mui/system';
import Typography from '../../common/Typography';
import globalMessages from '../../../utils/messages/en/globalMessages';

const useStyles = {
  cardMediaStyles: {
    padding: '24px 0px 58px 20px',
    width: '25.67px',
    height: '21px',
  },
  DemoscardMediaStyles: {
    padding: '24px 0px 58px 20px',
    width: '40px',
    height: '40px',
  },
  cardStyle: {
    display: 'flex',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: COLORS.PRIMARY_AIR,
    },
    width: '100%',
  },
  cardContent: {
    margin: '24px 24px',
    padding: '0px',
    width: '100%',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cardTitle: {
    marginBottom: '0px',
  },
  arrowIcon: {
    width: '14px',
    height: '14px',
    marginLeft: '8px',
    verticalAlign: 'middle',
    marginBottom: '2px',
  },
  contentArea: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '6px',
  },
  cardWrapperStyle: {
    display: 'flex',
    height: '100%',
    backgroundColor: COLORS.BACKGROUND_SECONDARY,
    width: '1005',
  },
};
export interface RouteCardProps {
  title: string;
  description: string;
  image: string;
  link: string;
  pageTitle?:
    | typeof globalMessages.homePage
    | typeof globalMessages.sdKPage
    | typeof globalMessages.apiReferencePages
    | typeof globalMessages.demoAndSampleAppPage;
}
const RouteCard: React.FC<RouteCardProps> = ({
  title,
  description,
  image,
  link,
  pageTitle,
}) => {
  return (
    <Card sx={useStyles.cardStyle}>
      <LinkWrapper href={link} isLinkFromCard={true}>
        <Box style={useStyles.cardWrapperStyle}>
          <CardMedia
            component="img"
            image={image}
            alt={title}
            sx={
              pageTitle === globalMessages.sdKPage
                ? useStyles.DemoscardMediaStyles
                : useStyles.cardMediaStyles
            }
          />
          <CardContent sx={useStyles.cardContent}>
            <Box style={useStyles.contentArea}>
              {title && 
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  style={useStyles.cardTitle}
                >
                  {title}
                </Typography>
              }
              <Box>
                <img
                  src={arrowRight}
                  style={useStyles.arrowIcon}
                  alt="arrow_right"
                />
              </Box>
            </Box>
            <Typography type="DESKTOP_BODY_PARAGRAPH">{description}</Typography>
          </CardContent>
        </Box>
      </LinkWrapper>
    </Card>
  );
};

export default RouteCard;
