import { Box } from '@mui/system';
import React from 'react';
import Typography from '../Typography';
import SEO from '../Seo';
import { Grid } from '@mui/material';
import GuideCard from '../GuideCard';
import RouteCard from '../RouteCard';
import globalMessages from '../../../utils/messages/en/globalMessages';
import theme, {
  BREAKPOINTS,
  MOBILE_SPACING,
  WIDTH_1024_SPACING,
} from '../../../utils/theme';

interface ReferencePageProps {
  title: string;
  description: string;
  caption?: string;
  items: any;
  isRouteCard?: boolean;
}

const useStyles = {
  boxBorder: {
    margin: 'auto',
    maxWidth: '1010px',
    paddingTop: '44px',
    [BREAKPOINTS.DEFAULT]: {
      marginLeft: theme.spacing(10),
      marginRight: theme.spacing(10),
      maxWidth: `calc( 100% - ${WIDTH_1024_SPACING.MARGIN} )`,
    },
    [BREAKPOINTS.MOBILE]: {
      paddingTop: '12px',
      margin: 'auto 20px',
      maxWidth: `calc( 100% - ${MOBILE_SPACING.MARGIN} )`,
    },
  },
  typographyStyle: {
    marginBottom: '20px',
    [BREAKPOINTS.MOBILE]: {
      marginBottom: '12px',
    },
  },
  cardGroupStyle: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit,minmax(min(100%, 350px),2fr))',
    gridGap: '24px',
    marginTop: '44px',
    [BREAKPOINTS.MOBILE]: {
      gridGap: '16px',
      marginTop: '5px',
    },
  },
  sdkCardGroupStyle: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(320px,3fr))',
    marginTop: '44px',
    gridGap: '24px',
    [BREAKPOINTS.MOBILE]: {
      gridGap: '16px',
      marginTop: '5px',
    },
  },
  guideCardGroupStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0px 32px',
    [BREAKPOINTS.CUSTOM]: {
      position: 'relative',
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(228.5px,1fr))',
      flex: '1',
      flexDirection: 'column',
      gridGap: '16px',
    },
  },
  desktopApiCardStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0px 32px',
  },
  cardStyle: {
    [BREAKPOINTS.CUSTOM]: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
    },
  },
};

const getCustomRouteCard = (title) => {
  return title === globalMessages.sdKPage
    ? useStyles.sdkCardGroupStyle
    : useStyles.cardGroupStyle;
};

const ReferencePage: React.FC<ReferencePageProps> = (props) => {
  const { title, description, caption, items, isRouteCard } = props;

  const buildCards = (card) => {
    if (isRouteCard) {
      return (
        <Grid item key={card.title} sx={useStyles.cardStyle}>
          <RouteCard {...card} pageTitle={title} />
        </Grid>
      );
    } else {
      return (
        <Grid item key={card.title} sx={useStyles.cardStyle}>
          <GuideCard {...card} pageTitle={title} />
        </Grid>
      );
    }
  };

  return (
    <>
      <SEO title={title} description={description} />
      <Box sx={useStyles.boxBorder}>
        <Box sx={useStyles.typographyStyle}>
          <Typography variant="h1">{title}</Typography>
        </Box>
        <Box sx={useStyles.typographyStyle}>
          <Typography type="DESKTOP_BODY_PARAGRAPH">{description}</Typography>
        </Box>
        {caption && 
          <Box sx={useStyles.typographyStyle}>
            <Typography type="DESKTOP_BODY_PARAGRAPH">{caption}</Typography>
          </Box>
        }
        <Box
          sx={
            isRouteCard
              ? getCustomRouteCard(title)
              : useStyles.guideCardGroupStyle
          }
        >
          {items
            .filter((item) => !item.hidden)
            .map((card) => {
              return buildCards(card);
            })}
        </Box>
      </Box>
    </>
  );
};

export default ReferencePage;
