import * as React from 'react';
import { Box, Card, CardContent, CardMedia } from '@mui/material';
import theme, { BREAKPOINTS } from '../../../utils/theme';
import LinkWrapper from '../Link';
import Typography from '../../common/Typography';
import globalMessages from '../../../utils/messages/en/globalMessages';
const useStyles = {
  cardStyle: {
    boxShadow: theme.shadows[11],
    '&:hover': {
      boxShadow: theme.shadows[13],
    },
    borderRadius: '4px',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
  },
  apiRefCard: {
    maxWidth: 315,
    height: 257,
    [BREAKPOINTS.CUSTOM]: {
      maxWidth: 'unset',
      height: 'unset',
    },
  },
  cardContent: {
    padding: '24px',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
  },
  cardTitle: {
    marginBottom: '8px',
  },
  linkStyle: {
    color: theme.palette.primary.main,
  },
  clickableArea: {
    cursor: 'pointer',
  },
  imgStyle: {
    minHeight: '100px',
    maxWidth: '100%',
    objectFit: 'cover',
    objectPosition: '50% 50%',
    maxHeight: '51%',
  },
  apiRefimgStyle: {
    height: '100px',
    [BREAKPOINTS.CUSTOM]: {
      minHeight: '100px',
      maxWidth: '100%',
      objectFit: 'cover',
      objectPosition: '50% 50%',
      maxHeight: '51%',
    },
  },
};
export interface GuideCardProps {
  title: string;
  description: string;
  image: string;
  link: string;
  pageTitle?:
    | typeof globalMessages.homePage
    | typeof globalMessages.sdKPage
    | typeof globalMessages.apiReferencePages
    | typeof globalMessages.demoAndSampleAppPage;
}
const GuideCard: React.FC<GuideCardProps> = ({
  title,
  description,
  image,
  link,
  pageTitle,
}) => {
  return (
    <Card
      sx={
        pageTitle === globalMessages.homePage
          ? useStyles.cardStyle
          : { ...useStyles.apiRefCard, ...useStyles.cardStyle }
      }
    >
      <CardMedia
        component="img"
        image={image}
        alt={title}
        sx={
          globalMessages.homePage
            ? useStyles.imgStyle
            : useStyles.apiRefimgStyle
        }
      />
      <Box style={useStyles.clickableArea}>
        <LinkWrapper href={link}>
          <CardContent sx={useStyles.cardContent}>
            {title && 
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                style={useStyles.cardTitle}
              >
                {title}
              </Typography>
            }
            <Typography type="DESKTOP_BODY_PARAGRAPH">{description}</Typography>
          </CardContent>
        </LinkWrapper>
      </Box>
    </Card>
  );
};

export default GuideCard;
