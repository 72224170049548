import React from 'react';
import { DemoAppsItems } from '../utils/DemoApps';
import ReferencePage from '../components/common/ReferencePage';

const DemoAppsPage: React.FC = () => {
  return (
    <ReferencePage
      title="Demos and Sample Apps"
      description="Check out the following demos and sample apps to learn how Skyflow can protect your sensitive data."
      items={DemoAppsItems}
      isRouteCard={true}
    ></ReferencePage>
  );
};

export default DemoAppsPage;
